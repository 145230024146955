import React from 'react'
import Layout from '../components/Layout'

const Error = () => {
  return (
    <Layout>
      <h2>404 not found</h2>
    </Layout>
  )
}

export default Error
